import express from "express";
import { getServerHelpers, getUniversalHelpers } from "../../helpers";
import { validateToken, validateTokenAndEnsureSelfAccountIdMatches } from "../../internal-utils/server-auth";
import { notifyOfExtremelyImportantError } from "../../utils/slack-utils";

export async function olliePipe__server__sendOlliePipeEvent(p: {
  type: string;
  payload: any;
  pipeOptions?: {
    sendImmediate?: boolean | undefined;
    disableDevTerminalLogging?: true | undefined;
    accountId?: string | undefined;
  };
  slackImportantErrorsMessage?: string;
}) {
  // SERVER_ONLY_TOGGLE
  const { olliePipe } = getUniversalHelpers();
  await olliePipe.emitEvent(
    {
      payload: p.payload,
      type: p.type
    },
    p.pipeOptions
  );

  if (p.slackImportantErrorsMessage) {
    try {
      notifyOfExtremelyImportantError({
        channel: "#important-errors",
        message: p.slackImportantErrorsMessage
      });
    } catch (e) {
      // Don't blow up
      await olliePipe.emitEvent(
        {
          payload: p.payload,
          type: "error-logging-to-slack-important-channels"
        },
        p.pipeOptions
      );
    }
  }
  // SERVER_ONLY_TOGGLE
}

olliePipe__server__sendOlliePipeEvent.auth = async (r: any) => {};

// i18n certified - complete
